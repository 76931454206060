<template>
    <div class="c-column-list-item" :class="item.type+'-list-item'">
        <router-link :to="routerLinkTo">
            <h2>{{item.title}}</h2>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'ColumnListArchiveItem',
    props: {
        item: {},
    },
    computed: {
        routerLinkTo() {
            return {
                name: 'ArchiveIndex',
                params: {
                    locale: this.$route.params.locale,
                },
                query: {
                    [this.item.category]: this.item.name
                }
            }
        },
    },
}
</script>
