<template>
    <div class="c-column-list-divider">
        <h2>{{item.title}}</h2>
    </div>
</template>

<script>
export default {
    name: 'ColumnListDivider',
    props: {
        item: {},
    },
}
</script>
