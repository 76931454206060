<template>
    <div v-if="items.length" class="c-column-list-index container-fluid">
        <template v-for="item in orderedItems" :key="item.id">
            <column-list-divider v-if="item.type == 'divider'" :item="item" />
            <column-list-archive-item v-else-if="item.type == 'archive'" :item="item" :image-display="imageDisplay" />
            <column-list-item v-else :item="item" :image-display="imageDisplay" />
        </template>
        <div v-if="imageDisplay !== 'inline'" id="column-list-hover-image"></div>
    </div>
    <p v-else class="loader-spinner"><span>Loading...</span></p>
</template>

<script>
import ColumnListItem from '@/components/ColumnListItem.vue';
import ColumnListArchiveItem from '@/components/ColumnListArchiveItem.vue';
import ColumnListDivider from '@/components/ColumnListDivider.vue';

export default {
    name: 'ColumnListIndex',
    components: {
        ColumnListItem,
        ColumnListArchiveItem,
        ColumnListDivider
    },
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        orderBy: {
            type: String,
            default: 'title'
        },
        indexBy: {
            default: false
        },
        imageDisplay: {
            type: String,
            default: 'inline'
        },
    },
    computed: {
        orderedItems() {
            if (this.orderBy !== 'false') {
                let orderedItems = [...this.items];
                if (this.items.length && this.indexBy) {
                    orderedItems = [...this.dividerItems, ...orderedItems];
                }
                return orderedItems.sort((a, b) => {
                    const compareValueA = (a[this.orderBy] ?? a.title).toString().toUpperCase();
                    const compareValueB = (b[this.orderBy] ?? b.title).toString().toUpperCase();
                    return compareValueA > compareValueB ? 1 : (compareValueA === compareValueB ? (a.title > b.title ? 1 : -1) : -1);
                });
            } else {
                return this.items;
            }
        },
        dividerItems() {
            let i = 97;
            return [...Array(26)].map(() => {
                return {
                    title: String.fromCharCode(i++).toUpperCase(),
                    type: 'divider'
                }
            });
        }
    }
}
</script>
