<template>
    <div class="c-column-list-item" :class="item.type+'-list-item'">
        <router-link :to="routerLinkTo" @mouseover="onMouseEnter"
      @mouseleave="onMouseLeave" @mousedown="onMouseLeave">
            <h2 v-html="item.title"></h2>
            <p v-if="item.previewText" v-html="item.previewText" />
            <p v-if="item.previewImage && imageDisplay == 'inline'" class="inline-preview-image">
                <img :src="item.previewImage.src" />
            </p>
        </router-link>
        <img v-if="hasHoverImage" class="hover-preview-image" :class="hoverImageClass" :src="item.previewImage.src" :width="item.previewImage.width" :height="item.previewImage.height" v-show="showHoverImage" />
    </div>
</template>

<script>
export default {
    name: 'ColumnListItem',
    props: {
        item: {},
        imageDisplay: {
            type: String,
            default: 'inline'
        }
    },
    data() {
        return {
            showHoverImage: false,
            hoverImageClass: '',
        }
    },
    computed: {
        routerLinkTo() {
            return {
                name: this.item.type.charAt(0).toUpperCase() + this.item.type.slice(1) +'Entry',
                params: {
                    locale: this.$route.params.locale,
                    id: this.item.id || null,
                    slug: this.item.slug || null,
                    categorySlug: this.item.category || null
                }
            }
        },
        hasHoverImage() {
            return !this.$globalData.isTouchDevice && this.imageDisplay == 'onHover' && this.item.previewImage;
        },
    },
    methods: {
        onMouseEnter(e) {
            if (this.hasHoverImage) {
                this.showHoverImage = true;
                this.hoverImageClass = e.clientX > window.innerWidth/3*2 ? 'left' : 'right';
                if (parseInt(this.item.previewImage.width) > parseInt(this.item.previewImage.height)) {
                    this.hoverImageClass += Math.random() < 0.5 ? ' top' : ' bottom';
                }
            }
        },
        onMouseLeave() {
            if (this.hasHoverImage) {
                this.showHoverImage = false;
            }
        },
    }
}
</script>
