<template>
    <div class="v-showcase-index index-view">
        <div class="view-content">
            <showcase-category-navigation />
            <column-list-index :items="items" image-display="onHover" :order-by="orderBy" :index-by="indexBy" />
        </div>
    </div>
</template>

<script>
import ShowcaseCategoryNavigation from '@/components/ShowcaseCategoryNavigation.vue';
import ColumnListIndex from '@/components/ColumnListIndex.vue'

import { fetchShowcaseEntriesByCategorySlug } from '../http/fetchApi';


export default {
    name: 'ShowcaseIndex',
    components: {
        ShowcaseCategoryNavigation,
        ColumnListIndex
    },
    props: {
        slug: String,
    },
    data() {
        return {
            items: []
        }
    },
    computed: {
        orderBy() {
            return (this.slug == 'wer' || this.slug == 'who') ? 'other_name' : 'title';
        },
        indexBy() {
            return (this.slug == 'wann' || this.slug == 'when') ? null : this.orderBy;
        }
    },
    mounted() {
        this.fetchEntries();
    },
    methods: {
        async fetchEntries() {
            const { data } = await fetchShowcaseEntriesByCategorySlug(this.slug);
            this.items = data;

            // set header data
            this.$globalData.setHeaderData({
                title: 'F+F 1971 - '+ this.slug.charAt(0).toUpperCase() + this.slug.slice(1)
            });
        },
    }
}
</script>
