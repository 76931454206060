<template>
    <nav class="c-showcase-category-navigation section-nav">
        <router-link :to="{ name: 'ShowcaseIndex', params: { locale: $route.params.locale, slug: $t('showcase.categories.who.slug')} }" :class="{'router-link-active': currentCategory == $t('showcase.categories.who.slug')}">{{$t('showcase.categories.who.name')}},</router-link> <router-link :to="{ name: 'ShowcaseIndex', params: { locale: $route.params.locale, slug: $t('showcase.categories.what.slug')} }" :class="{'router-link-active': currentCategory == $t('showcase.categories.what.slug')}">{{$t('showcase.categories.what.name')}},</router-link> <router-link :to="{ name: 'ShowcaseIndex', params: { locale: $route.params.locale, slug: $t('showcase.categories.when.slug')} }" :class="{'router-link-active': currentCategory == $t('showcase.categories.when.slug')}">{{$t('showcase.categories.when.name')}},</router-link> <router-link :to="{ name: 'ShowcaseIndex', params: { locale: $route.params.locale, slug: $t('showcase.categories.how.slug')} }" :class="{'router-link-active': currentCategory == $t('showcase.categories.how.slug')}">{{$t('showcase.categories.how.name')}}</router-link>
    </nav>
</template>

<script>
export default {
    name: 'ShowcaseCategoryNavigation',
    props: {
        currentCategory: String
    }
}
</script>
